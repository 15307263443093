.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    flex-wrap: nowrap;
    margin-top: 100px;
    text-decoration: none;
    margin-bottom: 100px;
}
.container button {
    background-image: url('../../../public/src/volver.png');
    background-size: 85%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(255, 255, 255, 0.45);
    border-color: transparent;
    border-radius: 50px;
    width: 60px;
    height: 60px;
    position: fixed;
    bottom: 15%;
    right: 1%;

}


.pub1 {
    width: 250px;
    background-color: white;
}

.pub2 {
    width: 250px;
    background-color: white;
}


.profesionales {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

@media (width <= 800px) {
    .pub1 {
        display: none;
    }

    .pub2 {
        display: none;
    }
 
}