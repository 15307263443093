.nav {
  background-color: rgba(228,163,232);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  filter: drop-shadow(0px 0px 10px rgba(195,137,199));
  position: fixed;
      top: 0;
  z-index: 1;
}
.logo {
  width: 80px;
}

.nombre {
  width: 250px;
}

@media (width <= 600px) {

  .logo {
    width: 50px;
  }

  .nombre {
    width: 200px;
  }
}

@media (width <= 375px) {

  .logo {
    width: 40px;
  }

  .nombre {
    width: 150px;
  }
}