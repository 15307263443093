.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
}

.publicidad {
    width: 20%;
    flex-direction: column;
}

.listado {
    width: 60%;
    flex-direction: column;
    padding-top: 50px;
}

/* .secciones {
    
} */

.farmacias {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(232, 246, 243);
}
.farmacias:hover {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(208, 236, 231);
}

.droguerias {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(234, 242, 248);
}
.droguerias:hover {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(212, 230, 241);
}

.estudios {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(245, 238, 248);
}
.estudios:hover {
    border: coral;
    border-style: double;
    height: 200px;
    margin: 50px;
    filter: drop-shadow(2px 4px 6px rgb(0,0,0));
    background-color: rgba(235, 222, 240);
}

