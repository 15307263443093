.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin-top: 70px;
    text-decoration: none;
    margin-bottom: 120px;
    justify-content: space-around;
    flex-wrap: nowrap;
}

.listado {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 50px;
}

.pub1 {
    width: 250px;
    background-color: white;
}

.pub2 {
    width: 250px;
    background-color: white;
}

.opciones {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    padding: 50px;
    flex-wrap: wrap;
        
}

.profesionales {
    display: flex;
    flex-direction: column;
    border: rgb(135,135,251);
    border-style: solid;
    /* margin-top: 50px; */
    width: 320px;
    filter: drop-shadow(1px 2px 3px rgb(0,0,0));
    transition: 0.3s ease;
    color: black;
    border-radius: 40px;
    margin: 10px;
}
.profesionales:hover {
    filter: drop-shadow(4px 8px 12px rgb(0,0,0));
    transition: 0.3s ease;

}
.profesionales img {
    margin: 10px;
    width: 50px;
    height: 50px;
}

.iconosProf {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    font-family: "Lemon Milk", Bold;
    color: white;
    background-color: rgb(135,135,251);
    font-size: x-large;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-decoration: none;
}
.iconosProf img {
    margin-left: 10px;
    width: 80px;
    height: 80px;
}


.centros {
    display: flex;
    flex-direction: column;
    border: rgb(174,135,251);
    border-style: solid;
    /* margin-top: 50px; */
    width: 320px;
    filter: drop-shadow(1px 2px 3px rgb(0,0,0));
    transition: 0.3s ease;
    color: black;
    border-radius: 40px;
    margin: 10px;
}
.centros:hover {
    filter: drop-shadow(4px 8px 12px rgb(0,0,0));
    transition: 0.3s ease;

}
.centros img {
    margin: 10px;
    width: 50px;
    height: 50px;
}

.iconosCen {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    font-family: "Lemon Milk", Bold;
    color: white;
    background-color: rgb(174,135,251);
    font-size: x-large;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-decoration: none;
}
.iconosCen img {
    margin-left: 10px;
    width: 80px;
    height: 80px;
}


.comercios {
    display: flex;
    flex-direction: column;
    border: rgb(251,147,135);
    border-style: solid;
    /* margin-top: 50px; */
    width: 320px;
    filter: drop-shadow(1px 2px 3px rgb(0,0,0));
    transition: 0.3s ease;
    color: black;
    border-radius: 40px;
    margin: 10px;
}
.comercios:hover {
    filter: drop-shadow(4px 8px 12px rgb(0,0,0));
    transition: 0.3s ease;

}
.comercios img {
    margin: 10px;
    width: 50px;
    height: 50px;
}

.iconosCom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    font-family: "Lemon Milk", Bold;
    color: white;
    background-color: rgb(251,147,135);
    font-size: x-large;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-decoration: none;
}
.iconosCom img {
    margin-left: 10px;
    width: 80px;
    height: 80px;
}



.empleos {
    width: 60%;
    display: flex;
    flex-direction: column;
    text-align: center;
    filter: drop-shadow(1px 2px 3px rgb(0,0,0));
    transition: 0.3s ease;
}

.iconosEmp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: 50%;
    font-family: "Lemon Milk", Bold;
    color: white;
    background-color: rgb(73,201,176);
    font-size: x-large;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    text-decoration: none;
}

.textosEmp {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 50%;
    /* text-align: left; */
    /* margin-left: 10px; */
    font-family: "Lemon Milk";
    font-weight: lighter;
    font-size: large;
    color: white;
    background-color: rgb(73,201,176);
    border-bottom-left-radius: 38px;
    border-bottom-right-radius: 38px;
    text-align: center;
    flex-direction: column;
    text-decoration: none;
}



.textos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 125px;
    /* text-align: left; */
    /* margin-left: 10px; */
    font-family: "Lemon Milk";
    font-weight: lighter;
    font-size: large;
    color: black;
    background-color: white;
    border-bottom-left-radius: 38px;
    border-bottom-right-radius: 38px;
    text-align: center;
    text-decoration: none;
}


@media (width <= 1150px) {
 
    .empleos {
        width: 320px;
    }
}

@media (width <= 800px) {
    .pub1 {
        display: none;
    }

    .pub2 {
        display: none;
    }
    
    .listado {
        align-content: center;
    }

    .opciones {
        padding: 1%;
    }
}

@media (width <= 600px) {
    .pub1 {
        display: none;
    }

    .pub2 {
        display: none;
    }
    
    .listado {
        align-content: center;
    }

    .opciones {
        padding: 1%;
    }
}

@media (width <= 360px) {
    .pub1 {
        display: none;
    }

    .pub2 {
        display: none;
    }
    
    .listado {
        align-content: center;
    }

    .opciones {
        padding: 1%;
    }

    .profesionales {
        width: 250px;
    }
    .iconosProf {
        font-size: medium;
    }
    
    .centros {
        width: 250px;
    }
    .iconosCen {
        font-size: medium;
    }

    .comercios {
        width: 250px;
    }
    .iconosCom {
        font-size: medium;
    }

    .textos {
        font-size: small;
    }

    .empleos {
        width: 250px;
    }
    .iconosEmp {
        font-size: medium;   
    }
    .textosEmp {
        font-size: small;
    }
}