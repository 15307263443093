.container {
    display: flex;
    width: 500px;
    margin: 20px;
    background: white;
    filter: drop-shadow(1px 2px 3px rgb(0, 0, 0));
    transition: 0.3s ease;
    /* border: springgreen; */
    border-style: solid;
    justify-content: space-between;
    flex-direction: column;
}
.container:hover {
    filter: drop-shadow(4px 8px 12px rgb(0,0,0));
    transition: 0.3s ease;
}



.detalles {
    display: flex;
    flex-direction: row;
    /* flex-wrap: wrap; */
    align-content: center;
    /* justify-content: space-evenly; */
    height: 200px;
    /* width: 400px; */
    align-items: center;

}

.iconos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 250px;
    height: 200px;
    font-weight: bold;
    font-size: large;
}
.iconos img {
    width: 100%;
    height: 100%;
}

.textos {
    display: flex;
    align-items: flex-start;
    width: 250px;
    text-align: left;
    margin-left: 10px;
    font-family: "Lora", TrueType;
    flex-wrap: wrap;
    flex-direction: column;
    font-size: medium;
}

.nombre {
    font-size: large;
    text-decoration: double;
    text-decoration-line: underline;
}
    
@media (width <= 600px) {
    .container {
        width: 400px;
    }

    .iconos {
        width: 200px;
    }

    .textos {
        width: 200px;
    }
}

@media (width <= 450px) {
    .container {
        width: 300px;
    }

    .iconos {
        width: 150px;
        /* height: 150px; */
    }

    .textos {
        width: 150px;
        font-size: small;
    }
}